<!-- 新闻资讯 luoll-->
<template>
    <div class="projectsupport-wrap">
        <div class="head-wrap">
            <h2><span></span>项目帮扶</h2>
            <div class="more" @click="$router.push({ path: '/promotion/projectList'})">查看更多<img src="@/assets/images/nationwide/more.png"/></div>
        </div>
        <div class="projectsupport-content">
            <ul class="projectsupport-list">
                <li v-for="(item,index) in dataList" :key="index">
                    <div class="projectsupport-container" @click="routerTo(item.id)">
                        <div class="img-container">
                            <img :src="item.thumbnail" alt="">
                            <span :class="item.status.text =='已完成' ? 'span_1':(item.status.text =='已发布'?'span_3':'span_2') ">{{item.status.text}}</span>
                        </div>
                        <div class="content-container">
                            <p class="item-title">
                                <label :class="(item.project_type.project_type == '1') ? 'red' : (item.project_type.project_type == '2'?'gray':'yellow')">
                                {{item.project_type.text}}
                                </label>
                                {{item.title}}
                            </p>
                            <p class="item-address">{{ item.province_id.text}}{{ item.city_id.text}}{{ item.twon_id.text}}</p>
                            <div class="demanRaise-info">
                                <p>目标：<span>{{item.project_price}}元</span></p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

    import {projectList} from "@/api/pc";

    export default {
        name: "projectsupport-style-1.vue",
        components: {},
        computed: {},
        data() {
            return {
                loadingNews : true,
                dataList: [],
            };
        },
        mounted() {
            this.getProjectListTop()
        },
        methods: {
            routerTo(id) {
                this.$router.push({ path: "/promotion/projectList/detail-"+id })
            },
            getProjectListTop(){
                const params = {
                    page: this.currentPage,
                    page_size: 3,
                    is_recommend: 1,
                }
                projectList(params || {})
                    .then(res => {
                        if(res.code == 10000) {
                            this.dataList = res.result.list
                            this.loading = false
                        }
                    })
                    .catch(err => {
                        this.loading = false
                    })
            },
        }
    }
</script>

<style  lang="scss">
    /*排行榜*/
    .projectsupport-wrap{
        width: 1210px;
        display: block;
        margin:0px auto;
        border-radius: 8px;
        position: relative;

        .head-wrap {
            padding: 10px 20px 10px 20px;
            position: relative;
            h2 {
                line-height: 48px;
                color: #333;
                padding: 0px 0px;
                font-size: 24px;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: left;
                span {
                    border: 3px solid $base-color;
                    border-radius: 4px;
                    height: 14px;
                    margin-right: 10px;
                    display: inline-block;
                }
            }
            .more{
                position: absolute;
                right:20px;
                bottom:10px;
                font-size: 14px;
                cursor: pointer;
                img{margin-left: 15px;}
            }
        }
    }
    .projectsupport-wrap .projectsupport-content{
        padding: 20px 30px;
        display: block;
        position: relative;
        height: 300px;
        overflow: hidden;
        background-color:#fff;
        border-radius: 8px;

        .projectsupport-list {
            width: 1150px;
            margin: 0 auto;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            li{
                box-sizing: border-box;
                width: 370px;
                margin: 10px 20px 0 0;
                border: 1px solid #E6E6E6;
                padding-bottom: 10px;
                position: relative;
                cursor: pointer;

                .projectsupport-container {
                    .img-container {
                        display: block;
                        width: 100%;
                        height: 160px;
                        object-fit: cover;
                        position: relative;

                        img {
                            width: 100%;
                            height: 160px;
                        }

                        span{
                            position: absolute;
                            top: -8px;
                            left: 15px;
                            display: block;
                            padding: 6px 12px;
                            font-size: 14px;
                            color: #fff;
                            line-height: normal;
                            letter-spacing: 0;
                            text-shadow: 0 1px 1px rgb(102 102 102 / 50%);
                            z-index: 2;
                            border-bottom-left-radius: 6px;
                            border-bottom-right-radius: 6px;
                        }
                        span.span_1{
                            background-color: $project-item-type-color-1 !important;
                        }
                        span.span_2{
                            background-color: $project-item-type-color-2 !important;
                        }
                        span.span_3{
                            background-color: $project-item-type-color-3 !important;
                        }
                        span::after {
                            content: "";
                            position: absolute;
                            left: -8px;
                            top:1px;
                            border: 4px solid #89540c;
                            border-left-color: transparent;
                            border-top-color: transparent;
                        }

                    }

                    .content-container {
                        padding: 10px 20px;

                        .item-title {
                            height: 24px;
                            font-size: 16px;
                            line-height: 24px;
                            color: #301D1D;
                            line-height: normal;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            word-break: break-all;
                            label{
                                font-size: 12px;
                                line-height: 20px;
                                color:#fff;
                                padding: 0px 4px;
                                margin-right: 5px;
                                border-radius: 6px;
                                display: inline-block;
                            }
                            label.red {
                                background-color: $base-color;
                            }

                            label.gray {
                                background-color: #8096b3;
                            }

                            label.yellow {
                                background-color: #ff850f;
                            }
                        }

                        .item-address {
                            padding-left: 17px;
                            margin: 10px 0;
                            background: url(https://www.zgshfp.com.cn/static/address.png) no-repeat left center;
                            background-size: 11px 12px;
                            font-size: 12px;
                            color: #999;
                            line-height: normal;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            word-wrap: normal;
                            word-break: break-all;
                        }

                        .demanRaise-info {
                            color: #484848;
                            font-size: 14px;

                            span {
                                color: $base-color
                            }
                        }
                    }
                }
            }
            li:nth-child(3n) {
                margin-right: 0px !important;
            }
        }
    }
    /*排行榜end*/
</style>