<template>
    <div class="floor-style-4">
            <el-row :gutter="10">
                <el-col class="mb10" :span="6" v-for="(item, index) in data" :key="index"
                        :title="item.goods_name" >
                    <div class="conter" @click="goSku(item.sku_id)">
                        <div class="styleBanner">
                            <img alt="" :src="$img(item.goods_image)" @error="imageError(index)">
                        </div>
                        <div class="styleTips textellipsis">
                            {{ item.goods_name }}
                        </div>
                        <div class="styleMoney">
                            <div class="money">
                                {{ item.price }}
                            </div>
                            <div class="live">
                                <img v-if="item.has_camera" src="@/assets/images/live_yes.png" alt="">
                                <img v-else src="@/assets/images/live_no.png" alt="">
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
    </div>
</template>

<script>
    export default {
        name: "shopDetail",
        props:{
          data:{
              type:Array,
              default:() =>[{
                  discount_price: "15.00",
                  goods_id: 114,
                  goods_image: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/557/common/images/20210430/20210430102433161974947330350.jpg,http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/557/common/images/20210430/20210430102433161974947315510.jpg,http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/557/common/images/20210430/20210430102433161974947343233.png,http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/557/common/images/20210430/20210430102433161974947339213.jpg,http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/557/common/images/20210430/20210430102433161974947337966.jpg",
                  goods_name: "湖南纯手工糯米红糖糍粑驴打滚年糕美食小吃500g/包",
                  goods_spec_format: null,
                  has_camera: false,
                  introduction: "糍粑是用糯米蒸熟捣烂后所制成的一种食品。含有较高的蛋白质（增强抵抗力）",
                  is_free_shipping: 1,
                  is_own: 0,
                  is_virtual: 0,
                  market_price: "0.00",
                  price: "15.00",
                  promotion_type: 0,
                  recommend_way: 0,
                  sale_num: 5,
                  site_id: 557,
                  site_name: "湖南龙邦农业开发有限公司",
                  sku_id: 116,
                  sku_image: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/557/common/images/20210430/20210430102433161974947330350.jpg",
                  sku_name: "湖南纯手工糯米红糖糍粑驴打滚年糕美食小吃500g/包 ",
                  stock: 984,
                  website_id: 0,
              }]
          }
        },
        methods:{
            goSku(skuId) {
                this.$router.pushToTab("/sku-" + skuId)
            },
            imageError(index) {
                this.data[index].sku_image = this.defaultGoodsImage;
            }
        }
    }
</script>

<style scoped type="text/scss" lang="scss">
  .floor-style-4 {
    margin-bottom: 20px;

    .floortitle {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      margin: 20px 0 20px 0;
      font-size: 20px;
    }
    .conter:hover{
      box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
    }
    .conter {
      padding: 17px;
      height: 300px;
      box-sizing: border-box;
      background-color: #f5f5f5;
      margin:0 5px;
      cursor: pointer;
      .styleBanner {
        height: 200px;
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: start;

        img {
          max-width: 100%;
        }
      }

      .styleTips {
        color: #333333;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 10px;
      }

      .styleMoney {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .money {
          color: #ff547b;
          font-size: 16px;
        }

        .live {
          width: 40px;
        }
      }
    }

    .leftImg {
      height: 617px;
      display: flex;
      align-items: start;
      justify-content: center;

      img {
        max-width: 100%;
      }
    }
  }
</style>
