<template>
	<div class="floor-style-13">
		<div class="img-box">
			<div class="item" v-if="data.value.image1.value.url" >
				<img :src="$img(data.value.image1.value.url)" @click="$router.pushToTab(data.value.image1.value.link.url)">
			</div>
			<div style="width:20px;">&nbsp;</div>
			<div class="item" v-if="data.value.image2.value.url">
				<img :src="$img(data.value.image2.value.url)" @click="$router.pushToTab(data.value.image2.value.link.url)">
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'floor-style-13',
	props: {
		data: {
			type: Object
		}
	},
	data() {
		return {};
	},
	created() {},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	methods: {
		imageError(index) {
			this.data.value.goodsList.value.list[index].sku_image = this.defaultGoodsImage;
		}
	}
};
</script>

<style lang="scss">
.floor-style-13 {
	margin: 40px 0;
	.img-box{
		padding:0;
		display: flex;
		.item{
			width: 595px;
			height: 300px;
			cursor: pointer;
			// padding: 0 5px;
			img{
				width: 100%;
				height: 100%;
				border-radius: 14px;
			}
		}
	}
}
</style>
