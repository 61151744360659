<template>
	<div class="floor-style-12">
		<div class="img-box">
			<div class="item" v-if="data.value.image1.value.url">
				<img :src="$img(data.value.image1.value.url)" @click="$router.pushToTab(data.value.image1.value.link.url)">
			</div>
			<div class="item" v-if="data.value.image2.value.url">
				<img :src="$img(data.value.image2.value.url)" @click="$router.pushToTab(data.value.image2.value.link.url)">
			</div>
			<div class="item" v-if="data.value.image3.value.url">
				<img :src="$img(data.value.image3.value.url)" @click="$router.pushToTab(data.value.image3.value.link.url)">
			</div>
			<div class="item" v-if="data.value.image4.value.url">
				<img :src="$img(data.value.image4.value.url)" @click="$router.pushToTab(data.value.image4.value.link.url)">
			</div>
			<div class="item" v-if="data.value.image5.value.url">
				<img :src="$img(data.value.image5.value.url)" @click="$router.pushToTab(data.value.image5.value.link.url)">
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'floor-style-12',
	props: {
		data: {
			type: Object
		}
	},
	data() {
		return {};
	},
	created() {},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	methods: {
		imageError(index) {
			this.data.value.goodsList.value.list[index].sku_image = this.defaultGoodsImage;
		}
	}
};
</script>

<style lang="scss">
.floor-style-12 {
	margin: 20px 0;
	.img-box{
		padding: 27px 30px;
		display: flex;
		background: #ffffff;
		.item{
			width: 230px;
			height: 130px;
			padding: 0 5px;
			box-sizing: border-box;
			cursor: pointer;
			img{
				width: 100%;
				height: 100%;
				border-radius: 7px;
			}
		}
	}
}
</style>
