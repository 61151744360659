<template>
    <div class="centerTabs">
        <div class="navList">
            <div class="navList-div" :style="{fontSize:size+'px'}" @click="navIndexClick(item,index)"
                 v-for="(item,index) of navList" :key="index">
                {{ item.name }}
                <div v-if="index === navIndex" :style="{height:borderHeight+ 'px'}" class="navList-border"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "centerTabs",
        props: {
            size: {
                type: Number,
                default: 24
            },
            borderHeight: {
                type: Number,
                default: 6
            },
            navIndex: {
                type: [Number,String],
                default: 0
            },
            navList: {
                type: Array,
                default: ()=> [
                    {
                        name: "长沙",
                        id: "1"
                    },
                    {
                        name: "安化",
                        id: "2"
                    }
                ]
            },
        },
        data() {
            return {
            }
        },
        methods: {
            navIndexClick(item, index) {
                this.$emit('navClick',item,index)
            }
        },
        mounted() {
            // console.log(this.navIndex,'navIndex')
        }
    }
</script>

<style scoped type="text/scss" lang="scss">
  .centerTabs {
    .navList {
      margin-bottom: 20px;
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      //flex-wrap: wrap;
        text-align: center;
      white-space: nowrap;
      &-div {
        color: #333333;
        display: inline-block;
        position: relative;
        text-align: center;
        font-size: 24px;
        min-width: 100px;
        box-sizing: border-box;
        cursor: pointer;
        margin: 0 5px;
      }

      &-border {
        position: absolute;
        height: 2px;
        background-color: #333333;
        width: 100%;
        bottom: 0;
        min-width: 100px;
      }
    }
  }
</style>
