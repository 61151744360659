<!-- 新闻资讯 luoll-->
<template>
    <div class="news-wrap">
        <div class="floor-head">
            <h2>
                <img src="@/assets/images/nationwide/title_l.png" />
                新闻资讯
                <img src="@/assets/images/nationwide/title_r.png" />
            </h2>
        </div>
        <div class="news-content">
            <div class="c-left">
                <el-carousel height="300px" arrow="hover" class="news-carousel">
                    <el-carousel-item v-for="item in recentNewsList" :key="item.id">
                        <div class="news-info" @click="viewDetail(item.id)">
                            <el-image :src="item.thumbnail.path" fit="cover" />
                            <div class="title">{{ item.title }}</div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="c-right">
                <el-tabs class="news-list" v-model="activeName">
                    <el-tab-pane v-for="(item, index) in newsList" :key="index" :label="item.title" :name="item.title"
                        v-loading="loadingNews">
                        <div class="news-body">
                            <div class="news-item" v-for="(citem, cindex) in articleNewsList" :key="cindex">
                                <a @click="viewDetail(citem.id)">{{ citem.title }}</a>
                                <span class="date">{{ updateTime(citem.update_time) }}</span>
                            </div>
                        </div>
                    </el-tab-pane>
                    ·
                </el-tabs>
                <div class="more" @click="$router.push({ path: '/promotion/newsInformation' })">查看更多<img
                        src="@/assets/images/nationwide/more.png" /></div>
            </div>
            <div class="clear"></div>
        </div>
    </div>
</template>

<script>
import { articleCategoryList, articleLists } from "@/api/cms/article"

export default {
    name: "news-style-1.vue",
    components: {},
    computed: {
        updateTime() {
            return (time) => {
                var timer = time.slice(0, 10)
                return timer
            }
        }
    },
    data() {
        return {
            loadingNews: true,
            activeName: "最新动态",
            activeId: 0,
            articleNewsList: [],
            recentNewsList: [],
            newsList: []
        }
    },
    mounted() {
        this.getArticleCategoryList()
        this.getArticleLists()
    },
    watch: {
        activeName: function (val) {
            this.newsList.forEach(element => {
                if (val === element.title) {
                    this.activeId = element.id

                    // 获取文章列表
                    this.getArticleLists()
                }
            })
        }
    },
    methods: {
        viewDetail(id) {
            this.$router.push({ path: "/promotion/newsInformation/detail-" + id })
        },
        // 获取文章分类列表
        getArticleCategoryList() {
            articleCategoryList()
                .then(res => {
                    if (res.code == 0 && res.data) {
                        this.newsList = res.data
                        // 添加默认tab
                        this.newsList.unshift({
                            title: "最新动态",
                            id: 0
                        })
                    }
                })
                .catch(err => {
                    this.$message.error(err.message)
                })
        },
        // 获取文章列表
        getArticleLists() {
            let _this = this
            let par = {
                page_size: 10,
                sort_field: "create_time",
                sort_type: "asc",
                category_id1: this.activeId
            }
            articleLists(par)
                .then(res => {
                    if (res.code == 0 && res.data) {
                        // 写入左侧轮播图
                        if (_this.activeName == "最新动态") {
                            this.recentNewsList = res.data.list
                        }
                        this.articleNewsList = res.data.list
                        this.loadingNews = false
                    }
                })
                .catch(err => {
                    this.$message.error(err.message)
                })
        }
    }
}
</script>

<style lang="scss">
/*排行榜*/
.news-carousel .el-carousel__indicators--horizontal {
    bottom: 30px;
}

.news-carousel .el-carousel__indicators--horizontal .el-carousel__button {
    width: 15px;
}

.news-carousel .news-info {
    width: 470px;
    height: 300px;

    img {
        width: 470px;
        height: 270px;
    }

    .title {
        background-color: #000;
        color: #fff;
        margin-top: -8px;
        height: 32px;
        padding-left: 11px;
        line-height: 32px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.news-wrap {
    width: 1210px;
    display: block;
    margin: 20px auto 0px;
    background-color: #fff;
    border-radius: 8px;
    position: relative;
}

.news-wrap .news-content {
    padding: 0px 30px;
    display: block;
    position: relative;
    height: 360px;
    overflow: hidden;

    .c-left {
        position: relative;
        width: 470px;
        height: 345px;
        float: left;
        padding-top: 15px;
    }

    .c-right {
        position: relative;
        width: 650px;
        height: 100%;
        float: right;
    }

    .clear {
        clear: both;
        zoom: 1;
    }

    .more {
        position: absolute;
        right: 0px;
        bottom: 15px;
        font-size: 14px;
        cursor: pointer;

        img {
            margin-left: 15px;
        }
    }
}

.news-wrap .news-list {
    width: 100%;
    display: block;
    margin: 0px;

    .news-body {
        .news-item {
            height: 32px;
            line-height: 32px;
            position: relative;
            display: flex;

            a {
                width: 80%;
                font-size: 15px;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-right: 10px;
            }

            span {
                width: 20%;
                display: block;
                font-size: 14px;
                line-height: 36px;
                text-align: right;
                color: #999999;
            }
        }
    }
}

.news-list.el-tabs>.el-tabs__header .el-tabs__nav {
    width: 60%;
    border: none;
    display: flex;
    margin: 0 16% 0 0;
}

.news-list.el-tabs>.el-tabs__header .el-tabs__item {
    width: 25%;
    height: 43px;
    color: #999;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: 42px;
}

.news-list.el-tabs .el-tabs__content {
    height: 250px;
    overflow: hidden;
    position: relative;
    line-height: 200px;
    font-size: 15px;
    border: none;
}

.el-tabs__nav-wrap::after {
    background-color: #fff;
}

.news-list.el-tabs>.el-tabs__header .el-tabs__item.is-active {
    color: $base-color;
}

.news-wrap .news-list .news-body {
    padding: 0;
    position: relative;
}

/*排行榜end*/
</style>
