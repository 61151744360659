<template>
	<div class="floor-style-14">
		<div class="body-wrap">
			<div class="bg-img">
				<router-link :to="data.value.bjImg.value.link.url"><img :src="$img(data.value.bjImg.value.url)"></router-link>
			</div>
			<ul class="goods-list">
				<li v-for="(item, index) in data.value.goodsList.value.list" :key="index" :title="item.goods_name" @click="goSku(item.sku_id)">
					<span class="tag taggreen" v-if="item.recommend_way ==1">新品</span>
					<span class="tag tagblue" v-if="item.recommend_way ==2">精品</span>
					<span class="tag" v-if="item.recommend_way ==3">推荐</span>
					<div class="goods-wrap">
						<div class="img-wrap">
							<img alt="商品图片" :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" />
						</div>
						<h3>
						<span class="p_tag_832" v-if="item.tag_type == 1">832产品</span>
						<span class="p_tag_832" v-if="item.tag_level == 2">省贫认定</span>
						<span class="p_tag_832" v-if="item.tag_level == 3">市贫认定</span>
						<span class="p_tag_832" v-if="item.tag_level == 4">县贫认定</span>{{ item.goods_name }}</h3>
						<p class="price">
							<span class="num">¥{{ item.discount_price }}</span>
						</p>
					</div>
				</li>
			</ul>
			<!-- <ul class="goods-list">
				<li title="开封市兰考县农家花生米5斤散装花生果花生籽">
					<div class="goods-wrap">
						<div class="img-wrap">
							<img alt="商品图片" src="https://mallcoc.oss-cn-beijing.aliyuncs.com/supplier/UhWrQMencTPqlGuJALumkrRreJiJzKaY_mid.jpg.webp">
						</div>
						<h3>开封市兰考县农家花生米5斤散装花生果花生籽</h3>
						<p class="price"><span class="num">55.00元</span></p>
					</div>
				</li>
			</ul> -->
			<div class="clear"></div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'floor-style-14',
	props: {
		data: {
			type: Object
		}
	},
	data() {
		return {};
	},
	created() {},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	methods: {
		goSku(skuId) {
			this.$router.pushToTab('/sku-' + skuId);
		},
		imageError(index) {
			this.data.value.goodsList.value.list[index].sku_image = this.defaultGoodsImage;
		}
	}
};
</script>

<style lang="scss">
.floor-style-14 {
	// background: #ffffff;
	margin: 20px 0;
	// padding:13px;
	.body-wrap {
		position: relative;
		height: 570px;
		.bg-img{
			width:1210px;
			height: 570px;
			position: absolute;
			left: 0;
			top: 0;
			img{
				width: 100%;
				height: 100%;
				border-radius: 14px;
			}
		}

		.goods-list {
			padding:14px 8px;
			width: 1170px;
			background-color: rgba(255,255,255,0.6);
			border-radius: 14px;
			display: flex;
			position: absolute;
			left: 12px;
			bottom: 12px;
			li {
				width:183px;
				height:280px;
				overflow: hidden;
				margin:0 6px;
				background: #fff;
				border-radius: 7px;
				cursor: pointer;
				padding:0;
				transition: all 0.2s linear;
				position: relative;
				.tag {
					display: inline-block;
					position: absolute;
					top: 18px;
					right: 24px;
					z-index: 1;
					font-size: 12px;
					width: 36px;
					background-color: #e84440;
					color: #fff;
					text-align: center;
					border-radius: 2px;
					letter-spacing: 2px;
				}
				.tag.taggreen {
					background-color: #07b85a;
				}
				.tag.tagblue {
					background-color: #0077FF;
				}

				&:hover {
					z-index: 2;
					-webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					-webkit-transform: translate3d(0, -2px, 0);
					transform: translate3d(0, -2px, 0);
				}
				.goods-wrap{
					padding: 20px 0px;

					.img-wrap {
						width: 155px;
						height: 155px;
						margin: 0px auto;
						text-align: center;
						line-height: 160px;
						// box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
						border-radius: 16px;
						overflow: hidden;
						position:relative;

						img {
							border: none;
							position: absolute;
							margin-right: -75px;
							top:50%;
							left:50%;
							display: block;
							transform: translate(-50%,-50%);
						}
					}
					h3 {
						font-size: 14px;
						font-weight: normal;
						line-height: 20px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						height: 40px;
						-webkit-box-orient: vertical;
						margin: 10px 15px 3px;
					}
					.desc {
						margin:2px 15px;
						font-size: 13px;
						line-height:14px;
						color: #b0b0b0;
						text-align: center;
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
					}
					.price {
						margin:5px 15px 0px;
						font-size: 16px;
						text-align: center;
						color: $base-color;
						del {
							margin-left: 0.5em;
							color: #b0b0b0;
						}
					}
				}
			}
		}
	}
	.clear{
		clear: both;
	}
}
</style>
