<template>
	<div class="floor-style-11">
		<div class="body-wrap">
			<div class="left-wrap" v-if="data.value.leftImg.value.url">
				<img :src="$img(data.value.leftImg.value.url)" @click="$router.pushToTab(data.value.leftImg.value.link.url)" />
			</div>
			<ul class="goods-list">
				<li v-for="(item, index) in data.value.goodsList.value.list" :key="index" :title="item.goods_name" @click="goSku(item.sku_id)">
					<div class="subscript" v-if="data.value.subscript.value.url && data.value.subscript.value.show=='1'">
						<img :src="data.value.subscript.value.url">
					</div>
					<span class="tag taggreen" v-if="item.recommend_way ==1">新品</span>
					<span class="tag tagblue" v-if="item.recommend_way ==2">精品</span>
					<span class="tag" v-if="item.recommend_way ==3">推荐</span>
					<div class="goods-wrap">
						<div class="img-wrap">
							<img alt="商品图片" :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" />
						</div>
						<h3>
						<span class="p_tag_832" v-if="item.tag_type == 1">832产品</span>
						<span class="p_tag_832" v-if="item.tag_level == 2">省贫认定</span>
						<span class="p_tag_832" v-if="item.tag_level == 3">市贫认定</span>
						<span class="p_tag_832" v-if="item.tag_level == 4">县贫认定</span>{{ item.goods_name }}</h3>
<!--						<p class="desc">{{ item.introduction }}</p>-->
						<p class="price">
							<span class="num">¥{{ item.discount_price }}</span>
<!--							<del>{{ item.market_price }}元</del>-->
						</p>
					</div>
				</li>
			</ul>
			<div class="clear"></div>
		</div>
		<div class="bottom-wrap" v-if="data.value.bottomMore.value.url && false" @click="$router.pushToTab(data.value.bottomMore.value.url)">
			更多 <img src="@/assets/images/more.jpg" alt="更多">
			<!-- <img :src="$img(data.value.bottomMore.value.url)" @click="$router.pushToTab(data.value.bottomImg.value.link.url)" /> -->
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'floor-style-11',
	props: {
		data: {
			type: Object
		}
	},
	data() {
		return {};
	},
	created() {},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	methods: {
		goSku(skuId) {
			this.$router.pushToTab('/sku-' + skuId);
		},
		imageError(index) {
			this.data.value.goodsList.value.list[index].sku_image = this.defaultGoodsImage;
		}
	}
};
</script>

<style lang="scss">
.floor-style-11 {
	margin-top:40px;
	.head-wrap {
		padding: 20px 20px 10px 20px;
		background-color: #fff;
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;

		h2 {
			line-height: 48px;
			color: #333;
			padding: 0px 0px;
			font-size: 20px;
			cursor: pointer;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			text-align: left;

			span {
				border: 3px solid $base-color;
				border-radius: 4px;
				height: 14px;
				margin-right:10px;
				display: inline-block;
			}
		}
	}
	.body-wrap {
		position: relative;
		.left-wrap {
			float: left;
			width: 200px;
			height: 560px;
			margin-right: 10px;
			cursor: pointer;
			transition: all 0.2s linear;
			&:hover {
				z-index: 2;
				/*-webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);*/
				/*box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);*/
				-webkit-transform: translate3d(0, -2px, 0);
				transform: translate3d(0, -2px, 0);
			}
			img {
				max-width: 100%;
				cursor: pointer;
			}
		}
		.goods-list {
			float: left;
			width: 1000px;
			background-color: #fff;
			li {
				float:left;
				width:200px;
				height:280px;
				overflow: hidden;
				margin:0 auto;
				background: #fff;
				// border-right:1px solid #eee;
				// border-bottom: 1px solid #eee;
				cursor: pointer;
				padding:0;
				transition: all 0.2s linear;
				position: relative;
				.subscript{
					position: absolute;
					z-index: 99;
					left: 0;
					top: 0;
					width: 60px;
					height: 60px;
					img{
						width: 100%;
						height: 100%;
					}
				}

				.tag {
					display: inline-block;
					position: absolute;
					top: 18px;
					right: 24px;
					z-index: 1;
					font-size: 12px;
					width: 36px;
					background-color: #e84440;
					color: #fff;
					text-align: center;
					border-radius: 2px;
					letter-spacing: 2px;
				}
				.tag.taggreen {
					background-color: #07b85a;
				}
				.tag.tagblue {
					background-color: #0077FF;
				}

				&:hover {
					z-index: 2;
					-webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					-webkit-transform: translate3d(0, -2px, 0);
					transform: translate3d(0, -2px, 0);
				}
				.goods-wrap{
					padding: 20px 0px;

					.img-wrap {
						width: 155px;
						height: 155px;
						margin: 0px auto;
						text-align: center;
						line-height: 160px;
						// box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
						border-radius: 16px;
						overflow: hidden;
						position:relative;

						img {
							border: none;
							position: absolute;
							margin-right: -75px;
							top:50%;
							left:50%;
							display: block;
							transform: translate(-50%,-50%);
						}
					}
					h3 {
						font-size: 14px;
						font-weight: normal;
						line-height: 20px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						height: 40px;
						-webkit-box-orient: vertical;
						margin: 10px 15px 3px;
					}
					.desc {
						margin:2px 15px;
						font-size: 13px;
						line-height:14px;
						color: #b0b0b0;
						text-align: center;
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
					}
					.price {
						margin:5px 15px 0px;
						font-size: 16px;
						// text-align: center;
						color: $base-color;
						del {
							margin-left: 0.5em;
							color: #b0b0b0;
						}
					}
				}
			}
		}
	}
	.clear{
		clear: both;
	}
	.bottom-wrap {
		width: $width;
		height: 40px;
		line-height: 30px;
		text-align: center;
		color: #939393;
		cursor: pointer;
		overflow: hidden;
	}
}
</style>
