import Config from "@/utils/config"
import {
	login
} from "@/api/auth/login"
import {
	adList,noticeAndArticle
} from "@/api/website"
import {
	noticesList
} from "@/api/cms/notice"
import {
	articleList
} from "@/api/cms/article"
import {
	floors,
	floatLayer,
	rankingList,
	apiDefaultSearchWords
} from "@/api/pc"
import {
	mapGetters
} from "vuex"
import {
	couponList,
	receiveCoupon
} from "@/api/coupon"
import {
	goodsPage,
	timeList
} from "@/api/seckill"
import {
	SupportLists,
	Regionlists,
	Regiongoodslists,
	regionAreas,
	haveGoodsRegion,
	regionTree,
	regionGoodsLists
} from "@/api/supper"
import CountDown from "vue2-countdown"
import ar from "element-ui/src/locale/lang/ar"
import centerTabs from "@/components/centerTabs"
import shopDetail from "@/components/shop/shopDetail"
import Cookies from "js-cookie"

export default {
	name: "index",
	components: {
		CountDown,
		centerTabs,
		shopDetail
	},
	data: () => {
		return {
			loading: true,
			loadingFloor: true,
			loadingRank: true,
			tabArticle1:[],
			tabArticle2:[],
			tabNotice:[],
			adList: [],
			adLeftList: [],
			adLeftListShow: true,
			adRightList: [],
			adRightListShow: true,
			adFourNumList: [],
			adFourNumListShow: true,
			adOneBarList: [],
			adOneBarListShow: true,
			noticeList: [],
			articleList: [],
			supportList: [],
			couponList: [],
			regionlist: [],
			floorList: {
				localbranch: [],
				ranking: [],
				news: [],
				floor: [],
			},
			floatLayer: {
				is_show: false,
				link: {
					url: ""
				}
			},
			indexFloatLayerNum: 0,
			shopApplyUrl: Config.baseUrl + "/shop/login/register",
			shopCenterUrl: Config.baseUrl + "/shop/index/index",
			storeUrl: Config.baseUrl + "/store",
			isSub: false,
			siteId: 0,
			listData: [],
			seckillTimeMachine: {
				currentTime: 0,
				startTime: 0,
				endTime: 0
			},
			seckillText: "距离结束",
			backgroundColor: "", // 顶部banner背景颜色
			// 悬浮搜索
			searchType: "goods",
			searchTypeText: "商品",
			keyword: "",
			defaultSearchWords: "",
			isShow: false,
			showRinking: false,
			parentList: [],
			childList: [],
			parentValue: '',
			childValue: '',
			parentActive: 0,
			childActive: '',
			childregion_id: '',
			newsTabActive:'news'
		}
	},
	watch: {
		searchType() {
			this.searchTypeText = this.searchType == "goods" ? "商品" : "店铺"
		}
	},
	created() {
		//首页轮播
		this.getAdList()
		// this.getNotice()
		// this.getArticle()
		this.noticeAndArticle()
		//广告位
		this.getBigAdList() //左侧 2张
		this.getSmallAdList() //右侧 2张
		this.getFourNumAdList() //4个
		this.getOneBarAdList() //单个
		//弹出
		this.getFloatLayer()
		//楼层
		this.getFloors()
		this.$store.dispatch("site/siteInfo") // 站点信息
		this.getDefaultSearchWords() // 默认搜索类型
		this.getTimeList()
		// this.SupportListsData() // 帮扶单位专区
		// this.getCanReceiveCouponQuery()
		// this.allregionAreasData() //获取地方专区 区域

		//判断是否刷新页面
		this.pageReload();
	},
	mounted() {
		window.addEventListener("scroll", this.handleScroll)
	},
	computed: {
		...mapGetters(["defaultHeadImage", "addonIsExit", "defaultGoodsImage", "member", "siteInfo", "cartCount"]),
		optionLeft() {
			return {
				direction: 2,
				limitMoveNum: 2
			}
		},
		logined: function() {
			return this.member !== undefined && this.member !== "" && this.member !== {}
		}
	},
	methods: {
		noticeDetail(id) {
            this.$router.push({ path: "/cms/notice-" + id })
        },
		viewDetail(id) {
            this.$router.push({ path: "/promotion/newsInformation/detail-" + id })
        },
		logout() {
			this.$store.dispatch("member/logout")
			vue.$router.push(`/login?redirect=${encodeURIComponent(vue.$router.history.current.fullPath)}`)
		},
		tabArticleMore(){
			if(this.newsTabActive == 'gg'){
				this.$router.push({ path: "/cms/notice"})
			}else{
				this.$router.push({ path: "promotion/newsInformation"})
			}
		},
		//判断是否刷新页面[从分站跳转回来时，页面样式加载有误，需要重新刷新]
		pageReload() {
			let query = this.$route.query;
			if (query.back_index_gohome && query.back_index_gohome === 'back_index_gohome') {
				window.location.href = window.location.href;
			}
		},
		// 帮扶单位 父级点击
		async parentClick(item, index) {
			this.parentActive = index
			this.parentValue = item.id
			this.childList = await this.regionAreasData(item.id) // 获取地方专区 区域
			this.childValue = ''
			this.childActive = ''
			this.RegionlistsData()
		},
		// 帮扶单位 子级点击
		childClick(item, index) {
			this.childActive = index
			this.childValue = item.id
			this.RegionlistsData()

		},
		async allregionAreasData() {
			this.parentList = await this.regionAreasData('') // 获取地方专区 区域
			this.parentValue = this.parentList[0].id
			this.childList = await this.regionAreasData(this.parentList[0].id) // 获取地方专区 区域
			// this.childValue = this.childList[0].id
			this.RegionlistsData() // 地方专区 / 专区列表
		},
		// 获取地方专区 区域
		async regionAreasData(id) {
			let par = {
				city_id: id
			}
			return new Promise(resolve => {
				haveGoodsRegion(par).then(res => {
					let data = res.data.filter(item => {
						item.name = item.city_name || item.district_name
						item.id = item.city || item.district
						return item
					})
					resolve(data)
				})
			})

		},
		RegionlistsData() {
			let page = {
				page: 1,
				page_size: 3,
				city_id: this.parentValue,
				district_id: this.childValue,
			}
			// Regiongoodslists
			regionGoodsLists(page).then(res => {
				let data = res.data
				// console.log(page)
				// console.log(data)
				// this.childregion_id = data.region_info.region_id
				if (data.list.length) {
					this.regionlist = data.list.slice(0, 4)
				}
			})
		},

		// 帮扶单位专区
		SupportListsData() {
			let page = {
				page: 1,
				page_size: 3
			}
			SupportLists(page).then(res => {
				let data = res.data
				if (data.list.length) {
					this.supportList = data.list.slice(0, 3)
				}
			})
		},
		countDownS_cb() { },
		countDownE_cb() {
			this.seckillText = "活动已结束"
		},
		getAdList() {
			adList({
				keyword: "NS_PC_INDEX"
			})
				.then(res => {
					this.adList = res.data.adv_list
					for (let i = 0; i < this.adList.length; i++) {
						if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
					}
					this.backgroundColor = this.adList[0].background
					this.loading = false
				})
				.catch(err => {
					this.loading = false
				})
		},
		handleChange(curr, pre) {
			this.backgroundColor = this.adList[curr].background
		},
		/**
		 * 广告位大图
		 */
		getBigAdList() {
			adList({
				keyword: "NS_PC_INDEX_MID_LEFT"
			})
				.then(res => {
					this.adLeftList = res.data.adv_list
					for (let i = 0; i < this.adLeftList.length; i++) {
						if (this.adLeftList[i].adv_url) this.adLeftList[i].adv_url = JSON.parse(this.adLeftList[i].adv_url)
					}
					this.loading = false

					//控制显示隐藏
					if (res.data.adv_position.is_state == 0) {
						this.adLeftListShow = false
					}
				})
				.catch(err => {
					this.loading = false
				})
		},
		noticeAndArticle(){
			console.log('noticeAndArticle 001');
			noticeAndArticle({})
				.then(res => {
					this.tabArticle1 = res.data.article_1;
					this.tabArticle2 = res.data.article_2;
					this.tabNotice = res.data.notice;
				})
				.catch(err => {
					// this.loading = false
				})
		},
		/**
		 * 广告位小图
		 */
		getSmallAdList() {
			adList({
				keyword: "NS_PC_INDEX_MID_RIGHT"
			})
				.then(res => {
					this.adRightList = res.data.adv_list
					for (let i = 0; i < this.adRightList.length; i++) {
						if (this.adRightList[i].adv_url) this.adRightList[i].adv_url = JSON.parse(this.adRightList[i].adv_url)
					}
					this.loading = false

					//控制显示隐藏
					if (res.data.adv_position.is_state == 0) {
						this.adRightListShow = false
					}
				})
				.catch(err => {
					this.loading = false
				})
		},

		/**
		 * 广告位小图 4个
		 */
		getFourNumAdList() {
			adList({
				keyword: "NS_PC_INDEX_MID_FOUR"
			})
				.then(res => {
					this.adFourNumList = res.data.adv_list
					for (let i = 0; i < this.adFourNumList.length; i++) {
						if (this.adFourNumList[i].adv_url) this.adFourNumList[i].adv_url = JSON.parse(this.adFourNumList[i].adv_url)
					}
					this.loading = false
					//控制显示隐藏
					if (res.data.adv_position.is_state == 0) {
						this.adFourNumListShow = false
					}
				})
				.catch(err => {
					this.loading = false
				})
		},

		/**
		 * 广告位小图 单条
		 */
		getOneBarAdList() {
			adList({
				keyword: "NS_PC_INDEX_MID_BAR"
			})
				.then(res => {
					this.adOneBarList = res.data.adv_list
					for (let i = 0; i < this.adOneBarList.length; i++) {
						if (this.adOneBarList[i].adv_url) this.adOneBarList[i].adv_url = JSON.parse(this.adOneBarList[i].adv_url)
					}
					this.loading = false
					//控制显示隐藏
					if (res.data.adv_position.is_state == 0) {
						this.adOneBarListShow = false
					}
				})
				.catch(err => {
					this.loading = false
				})
		},
		getNotice() {
			noticesList({
				page: 1,
				page_size: 5,
				receiving_type: "web"
			})
				.then(res => {
					this.noticeList = res.data.list
				})
				.catch(err => err)
		},
		getArticle() {
			articleList({
				page: 1,
				page_size: 10,
				receiving_type: "web"
			})
				.then(res => {
					this.articleList = res.data.list
				})
				.catch(err => err)
		},
		//获取优惠券列表
		getCanReceiveCouponQuery() {
			couponList({
				activeName: "second",
				site_id: this.siteId
			})
				.then(res => {
					let data = res.data
					this.couponList = []
					if (data != null) {
						for (let i = 0; i < data.list.length; i++) {
							if (i < 5) {
								this.couponList.push(data.list[i])
							}
						}
						this.couponList.forEach(v => {
							v.useState = 0
						})
					}
				})
				.catch(err => { })
		},
		/**
		 * 领取优惠券
		 */
		receiveCoupon(item, index) {
			if (this.isSub) return
			this.isSub = true

			var data = {
				site_id: item.site_id,
				activeName: "second",
				platformcoupon_type_id: item.platformcoupon_type_id
			}

			receiveCoupon(data)
				.then(res => {
					var data = res.data
					let msg = res.message
					if (res.code == 0) {
						msg = "领取成功"
						this.$message({
							message: msg,
							type: "success"
						})
					} else {
						this.$message({
							message: msg,
							type: "warning"
						})
					}
					let list = this.couponList
					if (res.data.is_exist == 1) {
						for (let i = 0; i < list.length; i++) {
							if (list[i].platformcoupon_type_id == item.platformcoupon_type_id) {
								list[i].useState = 1
							}
						}
					} else {
						for (let i = 0; i < list.length; i++) {
							if (list[i].platformcoupon_type_id == item.platformcoupon_type_id) {
								list[i].useState = 2
							}
						}
					}

					this.isSub = false
					this.$forceUpdate()
				})
				.catch(err => {
					if (err.message == 'token不存在') {
						this.$router.pushToTab('/login')
					} else {
						this.$message.error(err.message);
					}
					this.isSub = false
				})
		},
		/**
		 * 点击优惠券
		 */
		couponTap(item, index) {
			if (item.useState == 0) this.receiveCoupon(item, index)
			else this.toGoodsList(item)
		},
		/**
		 * 去购买
		 */
		toGoodsList(item) {
			if (item.use_scenario != 1) {
				this.$router.push({
					path: "/list",
					query: {
						platformcouponTypeId: item.platformcoupon_type_id
					}
				})
			} else {
				this.$router.push("/list")
			}
		},
		/**
		 * 限时秒杀
		 */
		getTimeList() {
			timeList()
				.then(res => {
					if (res.code == 0 && res.data) {
						let time = new Date(res.timestamp * 1000)
						let currentTimes = res.timestamp

						res.data.list.forEach((v, k) => {
							if (v.seckill_start_time <= currentTimes && currentTimes < v.seckill_end_time) {
								let seckillId = v.id
								this.getGoodsList(seckillId)

								let endTime = parseInt(time.getTime() / 1000) + (v.seckill_end_time - currentTimes)
								this.seckillTimeMachine = {
									currentTime: res.timestamp,
									startTime: res.timestamp,
									endTime: v.seckill_end_time
								}
							}
						})
					}
				})
				.catch(err => {
					this.$message.error(err.message)
				})
		},
		/**
		 * 秒杀商品
		 */
		getGoodsList(id) {
			goodsPage({
				page_size: 0,
				seckill_id: id,
				site_id: this.siteId
			})
				.then(res => {
					if (res.code == 0 && res.data.list) {
						// console.log('秒杀==', res.data.list)
						this.listData = res.data.list
					}
				})
				.catch(err => { })
		},
		/**
		 * 图片加载失败
		 */
		imageError(index) {
			this.listData[index].sku_image = this.defaultGoodsImage
		},
		/**
		 * 图片加载失败
		 */
		adLeftImageError(index) {
			this.adLeftList[index].adv_image = this.defaultGoodsImage
		},
		/**
		 * 图片加载失败
		 */
		adRightImageError(index) {
			this.adRightList[index].adv_image = this.defaultGoodsImage
		},
		getFloors() {
			floors()
				.then(res => {
					var floorData = res.data;
					this.floorList.localbranch = []; //城市分站
					this.floorList.ranking = [];  //排行榜
					this.floorList.floor = []; //楼层分类
					this.floorList.news = [];  //新闻资讯
					floorData.map(item => {
						if (item.block_name === "floor-style-4" || item.block_name === "floor-style-6" || item.block_name === "floor-style-7") {
							this.floorList.ranking.push(item);
						} else if (item.block_name === "floor-style-5" || item.block_name === "floor-style-8") {
							this.floorList.localbranch.push(item);
						} else if (item.block_name === "floor-style-9") {
							this.floorList.news.push(item);
						} else {
							this.floorList.floor.push(item);
						}
					})
					// console.log('=====',this.floorList);
				})
				.catch(err => {
					console.log(err)
				})
		},
		getFloatLayer() {
			floatLayer()
				.then(res => {
					if (res.code == 0 && res.data) {
						this.floatLayer = res.data
						this.floatLayer.link = JSON.parse(this.floatLayer.url)
						// 弹框形式，首次弹出 1，每次弹出 0

						if (!this.floatLayer.img_url) return

						if (parseInt(this.floatLayer.number) == 1) {
							var index_popwindow_count = this.$store.state.app.indexFloatLayerNum;
							if (index_popwindow_count == '' || index_popwindow_count == 1) {
								this.floatLayer.is_show = true
								this.$store.commit("app/SET_FLOAT_LAYER", 1)
							}
						} else if (parseInt(this.floatLayer.number) == 0) {
							this.floatLayer.is_show = true
							this.$store.commit("app/SET_FLOAT_LAYER", 0)
						}
					}
				})
				.catch(err => err)
		},
		closeFloat() {
			this.floatLayer.is_show = false
			this.$forceUpdate()
			this.$store.commit("app/SET_FLOAT_LAYER", -1)
		},
		// 监听滚动条
		handleScroll() {
			var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

			if (scrollTop >= 680) {
				this.isShow = true
			} else {
				this.isShow = false
			}
		},
		destroyed() {
			// 离开该页面需要移除这个监听的事件，不然会报错
			window.removeEventListener("scroll", this.handleScroll)
		},
		/**
		 * 默认搜索类型（goods/shop）
		 */
		getDefaultSearchWords() {
			apiDefaultSearchWords({}).then(res => {
				if (res.code == 0 && res.data.words) {
					this.defaultSearchWords = res.data.words
				}
			})
		},
		handleCommand(command) {
			this.searchType = command
		},
		search() {
			if (this.searchType == "goods") this.$router.push({
				path: "/list",
				query: {
					keyword: this.keyword
				}
			})
			else this.$router.push({
				path: "/street",
				query: {
					keyword: this.keyword
				}
			})
		}
	}
}
